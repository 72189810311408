define("ember-popper-modifier/helpers/popper-modifier", ["exports", "@ember/component/helper", "ember-popper-modifier/index"], function (_exports, _helper, _index) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.buildPopperModifier = buildPopperModifier;
  _exports.default = void 0;

  function buildPopperModifier(_ref, optionsHash) {
    let [name, positionalOptions] = _ref;
    const options = { ...positionalOptions,
      ...optionsHash
    };
    return (0, _index.createModifier)({
      name,
      options
    });
  }

  var _default = (0, _helper.helper)(buildPopperModifier);

  _exports.default = _default;
});