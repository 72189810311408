define("ember-popper-modifier/-base-popper-modifier", ["exports", "ember-modifier", "@ember/array", "@ember/utils", "@ember/debug", "@popperjs/core", "ember-popper-modifier/index", "ember-popper-modifier/in-runloop-modifier"], function (_exports, _emberModifier, _array, _utils, _debug, _core, _index, _inRunloopModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PopperModifier extends _emberModifier.default {
    /** @type {HTMLElement} */
    // eslint-disable-next-line getter-return
    get tooltipElement() {
      (true && !(false) && (0, _debug.assert)("Must implement `tooltipElement` property", false));
    }
    /** @type {HTMLElement} */
    // eslint-disable-next-line getter-return


    get referenceElement() {
      (true && !(false) && (0, _debug.assert)("Must implement `referenceElement` property", false));
    }

    get popperOptions() {
      // Get an array of just positional "options"; first item is an element reference
      const positionalArguments = this.args.positional.slice(1).filter(arg => Boolean(arg)); // Positional args that are not modifiers should be treated as full "options" objects

      const allPositionalOptions = positionalArguments.filter(arg => !(0, _index.isModifier)(arg)); // Positional args that are modifiers will extend the rest of the configuration

      const allPositionalModifiers = positionalArguments.filter(arg => (0, _index.isModifier)(arg));
      const { ...namedOptions
      } = this.args.named;
      const options = { ...allPositionalOptions.reduce((acc, curr) => {
          return { ...acc,
            ...curr
          };
        }, {}),
        ...namedOptions
      }; // Ensure that the `modifiers` is always an array

      const modifiers = (0, _utils.isEmpty)(options.modifiers) ? [] : (0, _array.isArray)(options.modifiers) ? options.modifiers : [options.modifiers]; // Add runloop integration and positional modifiers to the array of modifiers

      options.modifiers = [...modifiers, ...allPositionalModifiers, _inRunloopModifier.beginRunLoopModifier, _inRunloopModifier.endRunLoopModifier];
      return options;
    }

    didReceiveArguments() {
      // Create the popper once all required arguments are present
      if (!this.popper && this.referenceElement && this.tooltipElement) {
        this.popper = (0, _core.createPopper)(this.referenceElement, this.tooltipElement, this.popperOptions);
        (0, _index.setPopperForElement)(this.element, this.popper);
      }
    }

    didUpdateArguments() {
      this.popper?.setOptions(this.popperOptions);
    }

    willRemove() {
      this.popper?.destroy();
    }

  }

  _exports.default = PopperModifier;
});