define("ember-popper-modifier/modifiers/popper", ["exports", "ember-popper-modifier/-base-popper-modifier"], function (_exports, _basePopperModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PopperModifier extends _basePopperModifier.default {
    get tooltipElement() {
      return this.args.positional[0];
    }

    get referenceElement() {
      return this.element;
    }

  }

  _exports.default = PopperModifier;
});