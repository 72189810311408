define("ember-popper-modifier/in-runloop-modifier", ["exports", "@ember/runloop"], function (_exports, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.endRunLoopModifier = _exports.beginRunLoopModifier = void 0;
  // This set keeps track of whether a particular Popper instance is currently in an update
  // loop that is tracked by the Ember Run Loop
  //
  // This is necessary in order to ensure that Popper changes are known to the Ember run loop,
  // and helps make sure that, for example, Ember's test helpers wait for any re-positioning to
  // take place before advancing
  //
  // We need to keep track of whether a run loop has already been started for an update cycle
  // because the number of "starts" and "ends" of the Popper update look do not always match;
  // if we "start" again before we have "ended", then we can open an Ember run loop that is never
  // closed, which will cause our application to hang.
  const POPPER_IN_RUN_LOOP = new WeakSet();
  const FIRST_PHASE = "beforeRead";
  const LAST_PHASE = "afterWrite";
  const beginRunLoopModifier = {
    name: "ember-runloop-begin",
    phase: FIRST_PHASE,
    enabled: true,

    fn(_ref) {
      let {
        instance
      } = _ref;

      if (!POPPER_IN_RUN_LOOP.has(instance)) {
        POPPER_IN_RUN_LOOP.add(instance);
        (0, _runloop.begin)();
      }
    }

  };
  _exports.beginRunLoopModifier = beginRunLoopModifier;
  const endRunLoopModifier = {
    name: "ember-runloop-end",
    phase: LAST_PHASE,
    enabled: true,

    fn(_ref2) {
      let {
        instance
      } = _ref2;

      if (POPPER_IN_RUN_LOOP.has(instance)) {
        POPPER_IN_RUN_LOOP.delete(instance);
        (0, _runloop.end)();
      }
    }

  };
  _exports.endRunLoopModifier = endRunLoopModifier;
});