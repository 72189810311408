define("ember-popper-modifier/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.createModifier = createModifier;
  _exports.getPopperForElement = getPopperForElement;
  _exports.isModifier = isModifier;
  _exports.setPopperForElement = setPopperForElement;
  const ELEMENT_TO_POPPER = new WeakMap();
  const IS_POPPER_MODIFIER = Symbol("is-popper-modifier");

  function getPopperForElement(element) {
    return ELEMENT_TO_POPPER.get(element);
  }

  function setPopperForElement(element, popperInstance) {
    ELEMENT_TO_POPPER.set(element, popperInstance);
  }
  /**
   * Mark an object as a Popper modifier
   *
   * @param {object} configuration
   * @return {object}
   */


  function createModifier(configuration) {
    return {
      [IS_POPPER_MODIFIER]: true,
      ...configuration
    };
  }
  /**
   * Check whether an object is a Popper modifier
   *
   * @param {object} configuration
   * @return {boolean}
   */


  function isModifier(configuration) {
    return configuration[IS_POPPER_MODIFIER] === true;
  }
});